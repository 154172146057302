@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

* {
  box-sizing: border-box; /* Asegúrate de que el padding y el borde se incluyan en el ancho y alto total */
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

:root {
  --fontsizeglobal: 18px;
}

.sidebar.locked .menu_item {
  transform: scale(0.98);
  left: -10px;
  position: relative;
}

.sidebar.locked .bx-lock-alt {
  position: relative;
  top: 30px !important;
  left: -22px;
}

.sidebar.locked > .logo_items > #lock-icon {
  position: relative !important;
  top: 30px !important;
  left: -165px !important; /*  32   */
}

.sidebar.locked .menu_title .title {
  display: none;
}

.sidebar.locked > .menu_container > .bottom-content {
  position: relative;

  left: -12px;
}

.sidebar.locked .logo_name {
  width: 0px !important;
  position: relative;
  left: -12px !important;
}

.sidebar.locked > .menu_container::-webkit-scrollbar {
  display: none !important;
}

.sidebar.locked + div > .css-qt54co {
  width: 190px !important;
}

.sidebar.locked + div > div:first-child {
  width: 80px !important;
}

/* Sidebar con la clase 'locked' */
.sidebar.locked {
  width: 60px !important; /* Ancho cuando está bloqueado */
  /*  background-color: red !important;  */
}

/* Opcional: Ocultar contenido cuando el sidebar esté bloqueado */
.sidebar.locked .menu_items,
.sidebar.locked .logo_items {
  //  display: none !important;  /* Oculta los elementos dentro del sidebar cuando esté bloqueado */
}

.sidebar.locked .menu_item,
.sidebar.locked .logo_name {
  /* opacity: 0 !important;    */ /* Opcional: Hace que el contenido del sidebar se vuelva invisible */
}

:root {
  --tran-05: all 0.4s ease;
}

/* Personalización del scroll en el menu_container */
.menu_container::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
}

.menu_container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Fondo de la pista del scroll */
  border-radius: 10px;
}

.menu_container::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #5fb6f4, #337d98); /* Degradado verde */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Espaciado dentro de la pista */
}

.menu_container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #4bb2ee, #1b89b1); /* Hover: cambio de color */
}

body {
  min-height: 100vh;
  background: #eef5fe;
  font-size: 15px; /* Tamaño base   0.7rem */
  /*  transform: scale(0.8); */ /* Reducir tamaño */
  transform-origin: top left; /* Define el punto de origen del escalado */
  /*   background-color:red!important;    */
}

.sidebar {
  /*    background-color:red!important;   */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 16rem; /* Ancho fijo para pantallas grandes */
  background: linear-gradient(to bottom right, #72c7ce, #3eaed8, #198bdb);
  padding: 15px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  z-index: 100;
  /*   transform: scale(0.9);  */ /* Reducir tamaño */
}

input:disabled {
  color: black !important; /* Cambia el color de la fuente a negro */
}

.fade-in {
  opacity: 1;
  transition: opacity 0.8s ease-in; /* Duración más larga para el fade-in */
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out; /* Fade-out más corto y suave */
}

.sidebar.locked .item.active {
  display: none !important;
}

.sidebar.locked .link:hover {
  color: white; /* Mantiene el color original */
  background-color: transparent; /* Sin color de fondo */
  cursor: pointer; /* Opcional: Desactiva la interacción visual */
}

.item.active {
  color: #fff;
  background-color: #ffffff31;
  border-radius: 8px;
}

.sidebar.close {
  width: calc(35px + 20px);
}

.nav_image {
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.flex {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.logo_items {
  gap: -px;
  margin-left: 16px;
}

.logo_name {
  font-size: 1.3em;
  color: #ffffff;
  font-weight: 500;
  margin-right: 150px;
  transition: all 0.3s ease;
}

.sidebar.close .logo_name,
.sidebar.close #lock-icon,
.sidebar.close #sidebar-close {
  opacity: 0;
  pointer-events: none;
}

#lock-icon,
#sidebar-close {
  padding: 10px;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

#sidebar-close {
  display: none;
  color: #ffffff;
}

.menu_container {
  margin-top: 40px;
  overflow-y: auto;
  height: calc(100% - 82px);
  overflow-x: hidden;
}

.menu_title {
  position: relative;
  height: 50px;
  width: 55px;
  color: white !important;
}

.menu_title .title {
  margin-left: 15px;
  transition: all 0.3s ease;
}

.sidebar.close .title {
  opacity: 0;
}

.menu_title .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 20px;
  border-radius: 25px;
  background: #aaa;
  opacity: 0;
}

.sidebar.close .line {
  opacity: 1;
}

.item {
  list-style: none;
}

.link {
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #ffffff;
}

.sub_menu_item {
  padding-left: 2rem;
}

.sub_menu_item .link {
  i {
    height: 32px;
  }
}

.ml-auto {
  margin-left: auto;
}

.link i {
  height: 50px;
  min-width: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.link span {
  white-space: nowrap;
}

.link:hover {
  color: #fff;
  background-color: #ffffff6a;
}

.sidebar .menu_container {
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerTrx {
  position: relative;
  left: 288px;
  height: 100vh;
  width: calc(100% - 288px);
  transition: var(--tran-05);
}

.sidebar.close ~ .containerTrx {
  left: 74px;
  width: calc(100% - 74px);
}

//danger
